import React from 'react';
import { Form, DatePicker, Button } from 'antd';

const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
// const config = {
//   rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
// };
const rangeConfig = {
  rules: [{ type: 'array' as const, required: true, message: 'Select start and end date to download bin logs from!' }],
};

export const DeviceLogRangeForm = (props: any) => {
  const onFinish = (fieldsValue: any) => {
    // Should format date value before submit.
    const rangeValue = fieldsValue['range-picker'];
    const values = {
      ...fieldsValue,
      'range-picker': [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')],
    };
    console.log(values);
    console.log(rangeValue);
    const rangeStart = values['range-picker'][0];
    const rangeEnd = values['range-picker'][1];
    console.log('Received values of form: ', values[0], values[1]);
    props.onSubmit(rangeStart, rangeEnd);
  };

  return (
    <Form name="time_related_controls" {...formItemLayout} onFinish={onFinish}>
      <Form.Item name="range-picker" label="Range" {...rangeConfig}>
        <RangePicker />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          sm: { span: 16, offset: 8 },
        }}
      >
        <Button type="primary" htmlType="submit">
          Download
        </Button>
      </Form.Item>
    </Form>
  );
};