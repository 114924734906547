// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import GrowerDTO from '../models/GrowerDTO';
import BinInfoDTO from '../models/BinInfoDTO';
import UserAlertSettingsDTO from '../models/UserAlertSettingsDTO';
import ForecastDTO from '../models/ForecastDTO';
import HourlyDTO from '../models/HourlyDTO';
import EnterpriseDTO from '../models/EnterpriseDTO';

export class EnterpriseApiService extends BaseApi {

    // get: api/enterprise/grower/${growerId}
    public getGrower(growerId: number, signal?: AbortSignal): Promise<GrowerDTO> {
        let url = `api/enterprise/grower/${growerId}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, GrowerDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/enterprise/growerids?onlyActive=${onlyActive}
    public getGrowerIDs(onlyActive?: boolean | null, signal?: AbortSignal): Promise<GrowerDTO[]> {
        let url = `api/enterprise/growerids`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (onlyActive != null) {
            url += `${prefix}onlyActive=${onlyActive}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, GrowerDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/enterprise/groweridsofuser?userID=${userID}
    public getGrowerIDsOfUser(userID: number, signal?: AbortSignal): Promise<GrowerDTO[]> {
        let url = `api/enterprise/groweridsofuser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userID != null) {
            url += `${prefix}userID=${userID}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, GrowerDTO[]>(null, 'get', url, true, false, _signal);
    }

    // post: api/enterprise/addGrower
    public addGrower(grower: GrowerDTO, signal?: AbortSignal): Promise<number> {
        let url = `api/enterprise/addGrower`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, number>(grower, 'post', url, true, false, _signal);
    }

    // post: api/enterprise/updateGrower
    public updateGrower(grower: GrowerDTO, signal?: AbortSignal): Promise<number> {
        let url = `api/enterprise/updateGrower`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, number>(grower, 'post', url, true, false, _signal);
    }

    // delete: api/enterprise/removeGrower?growerId=${growerId}
    public removeGrower(growerId: number, signal?: AbortSignal): Promise<boolean> {
        let url = `api/enterprise/removeGrower`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (growerId != null) {
            url += `${prefix}growerId=${growerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false, _signal);
    }

    // get: api/enterprise/weather?lat=${lat}&lng=${lng}
    public getWeatherForecast(lat: number, lng: number, signal?: AbortSignal): Promise<ForecastDTO> {
        let url = `api/enterprise/weather`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (lat != null) {
            url += `${prefix}lat=${lat}`;
            prefix = '&';
        }
        if (lng != null) {
            url += `${prefix}lng=${lng}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ForecastDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/enterprise/hourlyWeather?lat=${lat}&lng=${lng}
    public get(lat: number, lng: number, signal?: AbortSignal): Promise<HourlyDTO[]> {
        let url = `api/enterprise/hourlyWeather`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (lat != null) {
            url += `${prefix}lat=${lat}`;
            prefix = '&';
        }
        if (lng != null) {
            url += `${prefix}lng=${lng}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, HourlyDTO[]>(null, 'get', url, true, false, _signal);
    }

    // post: api/enterprise/addbin
    public addBin(binInfo: BinInfoDTO, signal?: AbortSignal): Promise<number> {
        let url = `api/enterprise/addbin`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, number>(binInfo, 'post', url, true, false, _signal);
    }

    // post: api/enterprise/updatebin
    public updateBin(binInfo: BinInfoDTO, signal?: AbortSignal): Promise<number> {
        let url = `api/enterprise/updatebin`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, number>(binInfo, 'post', url, true, false, _signal);
    }

    // post: api/enterprise/deletebin?binId=${binId}
    public deleteBin(binId: number, signal?: AbortSignal): Promise<void> {
        let url = `api/enterprise/deletebin`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // get: api/enterprise/getbinids
    public getBinIDs(signal?: AbortSignal): Promise<BinInfoDTO[]> {
        let url = `api/enterprise/getbinids`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinInfoDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/enterprise/getbinidsbygrower?growerID=${growerID}
    public getBinIDsByGrower(growerID: number, signal?: AbortSignal): Promise<BinInfoDTO[]> {
        let url = `api/enterprise/getbinidsbygrower`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (growerID != null) {
            url += `${prefix}growerID=${growerID}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinInfoDTO[]>(null, 'get', url, true, false, _signal);
    }

    // post: api/enterprise/targetMoisture/${binId}?mc=${mc}
    public changeBinTargetMoisture(binId: number, mc: number, signal?: AbortSignal): Promise<BinInfoDTO> {
        let url = `api/enterprise/targetMoisture/${binId}`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (mc != null) {
            url += `${prefix}mc=${mc}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinInfoDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/enterprise/userAlertSettings
    public getUserAlertSettings(signal?: AbortSignal): Promise<UserAlertSettingsDTO> {
        let url = `api/enterprise/userAlertSettings`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserAlertSettingsDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/enterprise/userAlertSettings
    public changeUserAlertSettings(dto: UserAlertSettingsDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/enterprise/userAlertSettings`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(dto, 'post', url, true, false, _signal);
    }

    // post: api/enterprise/targetPrice/${growerId}?tp=${tp}
    public changeGrowerTargetPrice(growerId: number, tp: number, signal?: AbortSignal): Promise<GrowerDTO> {
        let url = `api/enterprise/targetPrice/${growerId}`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tp != null) {
            url += `${prefix}tp=${tp}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, GrowerDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/enterprise/getbinbyid?id=${id}
    public getBinByID(id: number, signal?: AbortSignal): Promise<BinInfoDTO> {
        let url = `api/enterprise/getbinbyid`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinInfoDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/enterprise/getunuseddevices
    public getUnusedDevicesForBin(signal?: AbortSignal): Promise<string[]> {
        let url = `api/enterprise/getunuseddevices`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/enterprise/detail/${growerId}
    public getEnterpriseDetail(growerId: number, signal?: AbortSignal): Promise<EnterpriseDTO> {
        let url = `api/enterprise/detail/${growerId}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, EnterpriseDTO>(null, 'get', url, true, false, _signal);
    }
}
var service = new EnterpriseApiService();
export default service;
