import { Line } from "react-chartjs-2";
import React, { useCallback, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { useBinStateFromAzure } from "src/queries/useBinStateFromAzure";
import { getUserTimezoneOffset } from "../HomeScreen/BinDetails";
import { Button, DatePicker, Skeleton } from "antd";
import BinApiService from "src/api/BinApiService";
import { BaseChartProps } from "./TemperatureCableChart";
import { RangePickerProps } from "antd/es/date-picker";
import { queryClient } from "src";
import { ChartDatePicker } from "./Co2Chart";
import annotationPlugin from 'chartjs-plugin-annotation';

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>>[0];

export const CellStrengthChart = (props: BaseChartProps & {height?: number}) => {
    const currentDate = dayjs();
    const priorDate = currentDate.subtract(30, "days");

    const [value, setValue] = useState<RangeValue>([priorDate, currentDate]);

    const onDateChange = useCallback((values: RangeValue, formatter: any) => {
        setValue(values);
    }, []);

    const chartQuery = useQuery({
        queryKey: [...binDBKeys.bin(props.binId), "charts", "Cellular", { startDate: value?.[0], endDate: value?.[1] }],
        staleTime: Infinity,
        keepPreviousData: true,
        retry: 0,
        queryFn: async q => {
            const now = value?.[1];
            const priorMonth = value?.[0];
            const results = await BinApiService.getCellularStrengthHistory(
                props.binId,
                priorMonth?.toISOString()!,
                now?.toISOString()!,
                null,
                q.signal
            );
            queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });

            return results;
        },
        enabled: (props.binId ?? 0) > 0
    });

    if (chartQuery.data == null) {
        return <Skeleton loading></Skeleton>;
    }

    return (
        <div>
            <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
            <section style={{ width: "100%", minHeight: props.height ?? '40vh' }}>
                <Line
                    options={{
                        responsive: true,
                        animation: false,
                        datasets: {
                            line: {
                                pointRadius: 0, // disable for all `'line'` datasets,
                            }
                        },
                        aspectRatio: 3,
                        // maintainAspectRatio: false,
                        scales: {
                            x: {
                                type: "time",
                                time: {
                                    minUnit: "day"
                                }
                            },
                            y: {
                                beginAtZero: false,
                                title: {
                                    font: {
                                        size: 16
                                    },
                                    display: true,
                                    text: (chartQuery.data?.unitLabel)!
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: "top" as const
                            },
                            title: {
                                display: true,
                                text: "Cellular Strength Over Time"
                            },
                            tooltip: {
                                mode: "index",
                                intersect: false
                            },
                            annotation:{
                                  
                                annotations: {    
                                    box1: {
                                      type: 'box',
                                      yMin: -120,
                                      yMax: -110,
                                      backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                      drawTime: 'beforeDatasetsDraw'
                                    },
                                    box2: {
                                      type: 'box',
                                      yMin: -110,
                                      yMax: -100,
                                      backgroundColor: 'rgba(255, 80, 0, 0.1)',
                                      drawTime: 'beforeDatasetsDraw'
                                    },
                                    box3: {
                                      type: 'box',
                                      yMin: -100,
                                      yMax: -90,
                                      backgroundColor: 'rgba(255, 160, 0, 0.1)',
                                      drawTime: 'beforeDatasetsDraw'
                                    },
                                    box4: {
                                      type: 'box',
                                      yMin: -90,
                                      yMax: -80,
                                      backgroundColor: 'rgba(255, 255, 0, 0.1)',
                                      drawTime: 'beforeDatasetsDraw'
                                    },
                                    box5: {
                                      type: 'box',
                                      yMin: -80,
                                      yMax: -50,
                                      backgroundColor: 'rgba(0, 255, 0, 0.1)',
                                      drawTime: 'beforeDatasetsDraw'
                                    }
                                }
                            }
                        },
                    }}
                    data={{ datasets: [{ data: chartQuery.data.data, label: "", indexAxis: 'x' }] }}
                />
            </section>
        </div>
    );
};
