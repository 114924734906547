import * as React from "react";
import { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Switch, Spin, Button, Skeleton, notification } from "antd";
import BinDTO from "src/models/BinDTO";
import InfiniteScroll from "react-infinite-scroll-component";
import SolenoidIgnoredStateDTO from "src/models/SolenoidIgnoredStateDTO";
import SetIgnoredSolenoidsRequestDTO from "src/models/SetIgnoredSolenoidsRequestDTO";
import BinApiService from "src/api/BinApiService";
import DesiredIgnoredSolenoidDTO from "src/models/DesiredIgnoredSolenoidDTO";

export interface SolenoidControlModalProps {
    solenoidControlModalVisible: boolean;
    closeModal(): void;
    bin: BinDTO | undefined;
}

export const SolenoidControlModal: React.FC<SolenoidControlModalProps> = ({ solenoidControlModalVisible, closeModal, bin }) => {
    const [form] = Form.useForm<SetIgnoredSolenoidsRequestDTO>();
    const [allBinSolenoidIDs, setAllBinSolenoidIDs] = useState<SolenoidIgnoredStateDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const resetData = () => {
        let ignoredSolenoids : string[] = bin?.desiredProperties?.ignoredSolenoids ?? [];

        let allSolenoids: SolenoidIgnoredStateDTO[] = [];
        bin?.stacks?.forEach(s => {
            s.valves?.forEach(v => {
                if (v?.spoolCylinderId != null) {
                    let solenoidAvailable = ignoredSolenoids?.find(b => b === v.spoolCylinderId) != null ? false : true;
                    allSolenoids.push({
                        solenoidId: v.spoolCylinderId,
                        available: solenoidAvailable
                    });
                }
                if (v?.gateCylinderId != null) {
                    let solenoidAvailable = ignoredSolenoids?.find(b => b === v.gateCylinderId) != null ? false : true;
                    allSolenoids.push({
                        solenoidId: v.gateCylinderId,
                        available: solenoidAvailable
                    });
                }
            });
        });

        let requestForm: SetIgnoredSolenoidsRequestDTO = {
            deviceId: bin?.deviceId!,
            solenoids: allSolenoids
        };

        form.setFieldsValue(requestForm as any);
        setAllBinSolenoidIDs(allSolenoids);
    };

    useEffect(() => {
        if(bin && !form.isFieldsTouched()){
            resetData();
        }
        
    }, [bin]);

    const onFinish = async (values: SetIgnoredSolenoidsRequestDTO) => {
        try {
            setLoading(true);
            values.deviceId = bin?.deviceId!;
            const result = await BinApiService.setIgnoredSolenoids(values);
            notification.success({ message: "Successfully set solenoids to ignore" });
            closeModal();
        } catch (error) {
            notification.error({
                message: "Could not set solenoids to ignore",
                description: error?.errorDetails?.detail ?? "Contact support"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Solenoid Control"
            open={solenoidControlModalVisible}
            onCancel={() => {
                resetData();
                closeModal();
            }}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }} 
            width="20%"
        >
            <p style={{ fontSize: "0.875rem", fontStyle: "italic", color: "#888" }}>
                Solenoids that are checked will be actuated by the system, whereas those that are not will be ignored.
            </p>
            <Form scrollToFirstError={true} autoComplete="off" form={form} onFinish={onFinish}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: 500,
                        overflow: "auto",
                        padding: "0 16px",
                        border: "1px solid rgba(140, 140, 140, 0.35)"
                    }}
                >
                    <Spin spinning={loading}>
                        <InfiniteScroll
                            dataLength={allBinSolenoidIDs.length}
                            next={() => {}}
                            hasMore={false}
                            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <Form.List name="solenoids">
                                {fields => (
                                    <>
                                        {fields.map(({ name }) => {
                                            const solenoid = allBinSolenoidIDs[name];
                                            return (
                                                <Row style={{ padding: "10px" }}>
                                                    <Col>
                                                        <Form.Item name={[name, "available"]} valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <span style={{ paddingLeft: "10px" }}>{`${solenoid.solenoidId}`}</span>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </InfiniteScroll>
                    </Spin>
                </div>
                <Row style={{ paddingTop: "10px", justifyContent:"end"}}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};
