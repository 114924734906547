import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Button, Card, Col, ConfigProvider, Divider, Form, InputNumber, message, Modal, Popconfirm, Radio, RadioChangeEvent, Row, Segmented, Select, Skeleton, Space, Spin, Statistic, Tag, Tooltip, Typography } from "antd"
import dayjs, { Dayjs } from "dayjs"
import React, { useCallback, useState } from "react"
import BinDTO from "src/models/BinDTO"
import { binDBKeys } from "src/pages/binOverview/BinCommander"
import { deviceQueryKeys, getUserTimezoneOffset } from "src/pages/dashboard/HomeScreen/BinDetails"
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en);
import ReactTimeAgo from 'react-time-ago'
import { useBinStateFromDevice } from "src/queries/useBinStateFromDevice"
import BinApiService from "src/api/BinApiService"
import { invalidateBinState } from "./BinStatsPageParent"
import { delay, layout2 } from "./BinStatsPage"
import FanOffReason from "src/consts/FanOffReason"
import { OperatingMode } from "./BinVisualThree"
import { EditOutlined, QuestionCircleFilled, QuestionCircleOutlined } from "@ant-design/icons"
import { useForm } from "antd/es/form/Form"
import { fillEmpty } from "src/pages/features/WeatherMonitorForm"

interface HeaterControlProps {
    azureDeviceId: string,
    binId: number,
    binDTO: BinDTO | null | undefined
}

export const useSetHeaterMode = (deviceId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (params: {heaterDesiredOn: boolean | null}) => {
            const res = await BinApiService.setHeaterMode({heaterDesiredOn: params.heaterDesiredOn, deviceId: deviceId})
            return res;
        },
        onSuccess: async (data) => {
            if (data.success === true) {
                message.info("Updated heater state");
            }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: async (error, variables, context) => {
            if (error) {
                message.error("failed to update desired heater mode");
            }
        },
        onSettled: async () => {
            //await queryClient.invalidateQueries(deviceQueryKeys.stateFromDevice(deviceId));
            // wait a few seconds for fans/heaters to get updated...
            await delay(3 * 1000);
            try {
                const res = await BinApiService.uploadBinStateToAzure(deviceId);
                if (res.success === true) {
                    console.log("requested new binstate");
                }
            } catch (error) {
                console.error("error requesting binstate invalidation", error);
            }
        }
    });
}

export const formatNumber = (num: number | null | undefined, options: {decimalPlaces?: number | true, filler?: string, suffix?: string, showSuffixIfNoValue?: boolean}) => {
    const decimals = options?.decimalPlaces ?? 1;
    const filler = options.filler ?? "No Data";
    const suffix = options.suffix ?? "";
    const showSuffixIfNoValue = options.showSuffixIfNoValue ?? false;
    if (Number.isFinite(num)) {
        let safeNumber = num as number;
        let rounded: number | string = safeNumber;
        if (decimals === true) {
            rounded = safeNumber;
        }
        else {
            rounded = Number(safeNumber)?.toFixed(decimals);
        }
        return `${rounded}${suffix}`;
    } else {

        return `${filler}${showSuffixIfNoValue ? suffix : ''}`;
    }
}

export const formatHeaterStatus = (binDTO: BinDTO | null | undefined) => {
    if (binDTO == null) {
        return <Typography.Text>Status unknown</Typography.Text>;
    }
    const fanStatus = binDTO.fans?.some(fan => fan.isHeaterOn === true);
    if (fanStatus === true) {
        return <Typography.Text strong>Heater(s) are running</Typography.Text>
    }
    else if (fanStatus === false) {
        return <Typography.Text strong>Heater(s) are <Typography.Text strong>OFF</Typography.Text></Typography.Text>
    }
    else {
        return <Typography.Text>Unknown</Typography.Text>;
    }
}

export const formatBool = (status: boolean | null | undefined, options?: {true?: string, false?: string, null?: string, bold?: boolean}) => {
    const trueText = options?.true ?? "ON";
    const falseText = options?.false ?? "OFF";
    const nullText = options?.null ?? "No Data";

    let text = nullText;
    if (status == null) {
        text = nullText;
    } else if (status === true) {
        text = trueText;
    } else if (status === false) {
        text = falseText;
    }
    return <Typography.Text strong={options?.bold ?? true} >{text}</Typography.Text>;
}

export const HeaterMcReductionHelpText = () => {

    return <><span>
        When the fan & heater pauses due to weather, this offset temporarily increases the maximum EMC setpoint.
    </span>
    <br />
    <span>
        For example, if the EMC range is initially set between 5% - 15%, and there's a 5% "Heater EMC Offset", the upper EMC setpoint will be adjusted to 20%. This changes the EMC range to 5% - 20%, allowing the fan & heater to operate even when the air has higher moisture content.  This adjustment accounts for the heater's capability to lower the EMC of incoming air.
    </span>
    </>;
}


export enum DerivedHeaterMode {
    AUTO = "Auto",
    OFF = "Off",
    ON = "On",
}

export const useUpdateLayerGrainTemperature = (deviceId: string) => {
    return useMutation({
        mutationFn: async (params: {maxLayerGrainTemperatureF: number}) => {
            return await BinApiService.updateMaxLayerGrainTemp({deviceId: deviceId, temperatureF: params.maxLayerGrainTemperatureF});
        }
    });
}

export const useUpdateHeaterMcOffsetMutation = (deviceId: string) => {
    return useMutation({
        mutationFn: async (params: {heaterMcOffset: number}) => {
            return await BinApiService.setHeaterPlenumMcOffset(params.heaterMcOffset, deviceId);
        }
    });
}

const updateMaxGrainLayerTemperatureFormId = "update-max-layer-grain-temperature-form-id";

interface UpdateLayerTemperatureFormValues {
    maxLayerGrainTemperatureF: number;
}
interface UpdateLayerTemperatureFormProps {
    maxGrainTempDefault: number,
    onFinish: (values: UpdateLayerTemperatureFormValues) => void,
} 
const UpdateLayerTemperatureForm = (props: UpdateLayerTemperatureFormProps) => {

    const [form] = useForm<UpdateLayerTemperatureFormValues>();

    return (
                                    <Form form={form}
                                    id={updateMaxGrainLayerTemperatureFormId}
                                    onFinish={props.onFinish}
                                    initialValues={{
                                        maxLayerGrainTemperatureF: props.maxGrainTempDefault,
                                    }}
                                    >
                                    <Form.Item required {...layout2} label="Max Layer Temp" name="maxLayerGrainTemperatureF">
                                        <InputNumber addonAfter="°F" />
                                    </Form.Item>

                                </Form>
    );
}

const FormatFanHeaterEMCText = (props: {binDTO: BinDTO}) => {

    if (props.binDTO?.weatherMonitorState == null) {
        return null;
    }

    const originalMinEMC = props.binDTO?.weatherMonitorState?.minMcLimit;
    const originalMaxEMC = props.binDTO?.weatherMonitorState?.maxMcLimit;

    let offsetMaxEMC = originalMaxEMC;
    if (offsetMaxEMC != null) {
        offsetMaxEMC += props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset! ?? -5;
    }
    const showHeaterOffset = props.binDTO?.fanOperations?.desiredHeaterOn;
    return <>
        <Typography.Text>When paused, the fan and heater will run between {fillEmpty(originalMinEMC)}% and </Typography.Text>
        <Typography.Text delete={showHeaterOffset}>{fillEmpty(originalMaxEMC)}%</Typography.Text>
        {showHeaterOffset && <> <Typography.Text>{fillEmpty(offsetMaxEMC)}%</Typography.Text>. <Tag color="red"> +{props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset}% Heater EMC Offset</Tag></>}
        </>


}

export const HeaterControls = (props: HeaterControlProps) => {

    const [changeGrainLayerTemperature, setChangeGrainLayerTemperature] = useState(false);

    const mutateHeaterMode = useSetHeaterMode(props.azureDeviceId);

    const mutateGrainLayerTemperature = useUpdateLayerGrainTemperature(props.azureDeviceId);
    const updateHeaterMcOffsetMutation = useUpdateHeaterMcOffsetMutation(props.azureDeviceId);

    const onChangeClick = useCallback((option: RadioChangeEvent) => {
        let heaterMode: boolean | null = null;
        const value = option.target.value;
        if (value === DerivedHeaterMode.AUTO) {
            heaterMode = null;
        }
        else if (value === DerivedHeaterMode.OFF) {
            heaterMode = false;
        }
        else if (value === DerivedHeaterMode.ON) {
            heaterMode = true;
        }
        console.log("heater change to send: ", heaterMode);
        mutateHeaterMode.mutate({heaterDesiredOn: heaterMode});
    }, [mutateHeaterMode, props.azureDeviceId]);


    //const AmbientAvgTempF = 55.0;
    const AmbientAvgTempF = props.binDTO?.ambientAir?.temp;
    //const PlenumTempAvgF = 20.0;
    const PlenumTempAvgF = props.binDTO?.plenumAir?.temp;
    //const plenumTempLimitF = 105;
    const plenumTempLimitF = props.binDTO?.desiredProperties?.overrides?.plenumHighTempF ?? 105;


    const renderOffHeaterReason = (binDto: BinDTO | null | undefined) => {

        const reasons = [];

        if (binDto?.fanOperations?.offReason) {
            reasons.push(<Typography.Text>Reason fan(s) are OFF: <Typography.Text strong>{binDto?.fanOperations?.offReason}</Typography.Text></Typography.Text>)
        }
        const getNextPossibleResumeTime = (binDTO: BinDTO): Dayjs | null => {
            let nextPossibleResume = binDTO?.fanControllerState?.timeTillNextPlenumTemperatureCheck;

            const fanheaterNextResume = binDTO?.fanControllerState?.timeTillFanHeaterPlenumPauseIsDone;
            if (fanheaterNextResume != null) {
                // the fans have been shut off
                // use this time instead
                nextPossibleResume = fanheaterNextResume;
            }

            if (nextPossibleResume == null) {
                return null;
            }
            return dayjs(nextPossibleResume);
        }

        if (binDto?.fanControllerState?.plenumTemperatureAboveLimit) {
            const resumeTime = getNextPossibleResumeTime(binDto);
            if (resumeTime) {

                const elem =                 <Typography.Paragraph>
                <Typography.Text>Paused because the plenum temp exceeded safeguard limits</Typography.Text>
                <Typography.Text>Heater will attempt to resume&nbsp;
                <Typography.Text strong><ReactTimeAgo date={resumeTime.toDate()} locale="en-US" /></Typography.Text></Typography.Text>
            </Typography.Paragraph>
                reasons.push(elem);
            }
        }

        return reasons;
    }

    const getDerivedHeaterMode = (binDTO: BinDTO | null | undefined): DerivedHeaterMode | null => {
        let derivedHeaterMode: DerivedHeaterMode | null = null;
        if (binDTO?.fanOperations?.desiredHeaterOn === false) {
            return DerivedHeaterMode.OFF;
        }

        if (binDTO?.fanOperations?.desiredHeaterOn === true) {
            if (binDTO?.fanOperations?.ignoreLayerTemperature === true) {
                derivedHeaterMode = DerivedHeaterMode.ON;
            }
            else if (binDTO?.fanOperations?.ignoreLayerTemperature === false) {
                derivedHeaterMode = DerivedHeaterMode.AUTO;
            }
        }

        return derivedHeaterMode;
    }
    const derivedHeaterMode = getDerivedHeaterMode(props.binDTO)

    const heaterOffReasons = renderOffHeaterReason(props.binDTO);
    const heaterControlAllowed = [OperatingMode.Manual, OperatingMode.Dry, OperatingMode.PreDry, OperatingMode.TopDry].includes(props.binDTO?.operatingMode!)

    return (<>  <Card title="Heater Settings">

        <Row gutter={[48, 48]}>

            <Col md={24} lg={14}>
                <Skeleton loading={props.binDTO == null} active>
                <div style={{ width: "240px", display: "flex", verticalAlign: "top", gap: "16px", paddingBottom: "16px"}}>
                    <Tooltip title={!heaterControlAllowed ? `Controllable in Dry Mode or User Control mode` : ''}>
                        <Radio.Group disabled={!heaterControlAllowed} onChange={onChangeClick} value={derivedHeaterMode} buttonStyle="solid">
                            <Radio.Button value={DerivedHeaterMode.OFF}>Off</Radio.Button>
                            <Radio.Button value={DerivedHeaterMode.ON}>On</Radio.Button>
                            <Tooltip trigger={['click', 'hover']} title={props.binDTO?.operatingMode === OperatingMode.Manual ? `Automated heater control only available in Dry mode` : ""}>
                                <Radio.Button value={DerivedHeaterMode.AUTO} disabled={props.binDTO?.operatingMode === OperatingMode.Manual || !heaterControlAllowed}>Auto</Radio.Button>
                            </Tooltip>
                        </Radio.Group>
                    </Tooltip>
                    {mutateHeaterMode.isLoading && <Spin />}
                </div>

                    {[OperatingMode.Dry, OperatingMode.PreDry, OperatingMode.TopDry].includes(props.binDTO?.operatingMode!) && derivedHeaterMode === DerivedHeaterMode.AUTO && <>
                        <Typography.Paragraph>
                            <Typography.Text>
                                Heater will run when target layer's grain temp is
                            </Typography.Text>

                            <span style={{ whiteSpace: 'nowrap' }}>
                                <Typography.Text> below </Typography.Text>
                                <Space direction="horizontal">
                                    <Typography.Text strong>{formatNumber(props.binDTO?.fanOperations?.maxAllowedLayerTemperature, { decimalPlaces: 0, filler: "___" })}°F</Typography.Text>
                                    <Button icon={<EditOutlined onClick={() => {
                                        setChangeGrainLayerTemperature(true);
                                    }} />}></Button>
                                </Space>

                            </span>

                        </Typography.Paragraph>
                    </>}

                <Typography.Title level={5}>
                    <Space direction="horizontal" size="small">
                        <Typography.Text>Heater EMC Offset Settings</Typography.Text>
                        <Popconfirm destroyTooltipOnHide icon={null} title={<Space direction="horizontal" size={4}><Typography.Text>Adjust Heater EMC Offset</Typography.Text> <Tooltip title={<HeaterMcReductionHelpText />}>
    <QuestionCircleOutlined size={32} />
</Tooltip></Space>}
    okButtonProps={{
        form: 'test',
        htmlType: 'submit',
    }}
okText="Apply"
 description={<>
<Form initialValues={{
    heaterMcOffset: (props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset ?? 5),
}} id="test" onFinish={(vals) => {
    updateHeaterMcOffsetMutation.mutate({heaterMcOffset: vals.heaterMcOffset}, {
        onSuccess(data, variables, context) {
            if (data.success) {
                message.success("Heater EMC Offset applying...");
            }
            else {
                message.error("Problem setting heater EMC Offset");
            }
        },
        onError(error, variables, context) {
            console.error("Error applying heater EMC Offset", error);
            message.error("Error applying heater EMC Offset");
        },
    });
}}>
         <Form.Item name="heaterMcOffset" label="Heater EMC Offset"
             rules={[
                 {
                     type: 'number',
                     max: 20,
                     message: 'Max is 20%',
                 }
             ]}
         >
             <InputNumber addonAfter="%" />
         </Form.Item>
</Form>
</>}>
    <Button icon={<EditOutlined />} />
</Popconfirm>
                    </Space>
                    </Typography.Title>
                <Typography.Paragraph>
                    <FormatFanHeaterEMCText binDTO={props.binDTO!} /> 
                </Typography.Paragraph>

                <Typography.Title level={5}>Max Plenum Temp Safeguard</Typography.Title>
                <Typography.Paragraph>
                    <Typography.Text>If the plenum temp is above <Typography.Text strong>{formatNumber(plenumTempLimitF, {decimalPlaces: true})}°F for 1 minute</Typography.Text>, it will turn the heater off for 5 minutes.</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Text>The stacks are made of PVC & will melt if temperature exceeds 105°F for an extended period of time.</Typography.Text>
                </Typography.Paragraph>

                <Typography.Title level={5}>Suggested Heater Setpoint</Typography.Title>
                <Typography.Paragraph>
                    It's recommended that you set your burner thermostat at a 20°F rise above the ambient temperature, which is currently&nbsp;<Typography.Text strong>{formatNumber(AmbientAvgTempF, {decimalPlaces: 0})}°F</Typography.Text>.
                </Typography.Paragraph>
                </Skeleton>
            </Col>

            <Col md={24} lg={10}>
                <Typography.Title level={5}>Sensor Readings</Typography.Title>
                <Typography.Text>Plenum Temp: <Typography.Text strong>{formatNumber(PlenumTempAvgF, {decimalPlaces: 0})}°F</Typography.Text></Typography.Text>
                <br />
                <Typography.Text>Ambient Temp: <Typography.Text strong>{formatNumber(AmbientAvgTempF, {decimalPlaces: 0})}°F</Typography.Text></Typography.Text>

                <Divider />

                <Typography.Title level={5}>Heater Status</Typography.Title>
                <Typography.Text>{formatHeaterStatus(props.binDTO)}</Typography.Text>
                <br />
                {heaterOffReasons}

                {props.binDTO?.fans?.map((fan) => {
                    return <>
                        <br />
                        <Space direction="horizontal">
                            <Typography.Text>Heater {fan.id}: {formatBool(fan.isHeaterOn)}</Typography.Text>
                            {(props.binDTO?.hardwareYear ?? 0) >= 2022 && <Typography.Text>Amps: <Typography.Text strong>{formatNumber(fan.heaterAmps, {decimalPlaces: 2})}</Typography.Text></Typography.Text>}
                        </Space>
                    </>;
                })}

                <Divider />

  
                <Typography.Title level={5}>Additional Safeguard</Typography.Title>
                <Typography.Paragraph>
                    <Typography.Text>
                        If the plenum temp remains over {formatNumber(plenumTempLimitF, {decimalPlaces: true})}°F for 10 minutes, an additional safeguard will turn off the fan.
                    </Typography.Text>
                </Typography.Paragraph>
            </Col>

        </Row>



        <Modal
                        key={updateMaxGrainLayerTemperatureFormId}
                        centered={true}
                        open={changeGrainLayerTemperature}
                        onCancel={() => setChangeGrainLayerTemperature(false)}
                        destroyOnClose={true}
                        title="Update Max Layer Grain Temp"
                        bodyStyle={{ padding: 0 }}
                        footer={[
                            <Button key="back" onClick={() => setChangeGrainLayerTemperature(false)}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" htmlType='submit' disabled={mutateGrainLayerTemperature.isLoading} loading={mutateGrainLayerTemperature.isLoading} form={updateMaxGrainLayerTemperatureFormId}>
                                Update Max Layer Temp
                            </Button>,
                        ]
                        }
                        >
                        <Row justify="center">
                            <Col span={24}>
                                <UpdateLayerTemperatureForm onFinish={(values) => {
                                    mutateGrainLayerTemperature.mutate({maxLayerGrainTemperatureF: values.maxLayerGrainTemperatureF}, {
                                        onSuccess: (data, variables, context) => {
                                            if (data.success === true) {
                                                message.success("Updated max layer grain temperature");
                                                setChangeGrainLayerTemperature(false);
                                            }
                                            else {
                                                message.error("Problem updating max layer grain temperature");
                                            }
                                        },
                                        onError: (error) => {
                                            console.log("error while updating max layer grain temperature", error);
                                            message.error("Error while updating max layer grain temperature");
                                        },
                                    }
                                        );
                                }} maxGrainTempDefault={props.binDTO?.fanOperations?.maxAllowedLayerTemperature ?? 75} />
                                </Col>
                                </Row>
                                </Modal>

    </Card></>);
}