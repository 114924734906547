import { DeleteOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import Column from "antd/lib/table/Column";
import React, { useMemo } from "react";
import { Load, PendingLoadDeletes, PopconfirmYesNo } from "./BinStatsPage";

interface LoadTableProps {
    loadData: Load[],
    deleteLoad: (load: Load) => void,
    hasPendingLoadDeletes: () => boolean,
    pendingLoadDeletes: PendingLoadDeletes,

}

const calcTotalBushels = (loads: Load[]): number | null => {
    if (loads == null) {
        return null;
    }
    if ((loads?.length ?? 0) <= 0) {
        return null;
    }

    let sum = 0;
    for (const load of loads) {
        if (load.estimatedBu == null || load.estimatedBu < 0) {
            continue;
        }
        sum += load.estimatedBu;
    }
    return sum;
}

interface SumAndMC {
    totalBushels: number | null,
    avgMC: number | null,
}

const calcAvgMC = (loads: Load[]): SumAndMC => {
    const totalBushels = calcTotalBushels(loads);
    let avgMC = 0;
    if (totalBushels == null || totalBushels <= 0) {
        return { totalBushels: totalBushels, avgMC: null };
    }

    for (const load of loads) {
        const weight = load.estimatedBu / totalBushels;
        if (load.MC == null) {
            continue;
        }
        const weightedAvgMC = weight * load.MC;
        avgMC += weightedAvgMC;
    }

    return { totalBushels: totalBushels, avgMC };
}

export const ReviewLoadTable = (props: LoadTableProps) => {

    const { totalBushels, avgMC } = useMemo(() => calcAvgMC(props.loadData), [props.loadData]);

    return (
        <Table style={{ width: '100%' }} size="small" dataSource={props.loadData} pagination={{ position: ["topLeft", "bottomRight"], pageSize: 10 }}
            // summary={(_) => {
            //     const totalBushelsEntered = totalBushels;

            //     if (totalBushelsEntered == null || avgMC == null) {
            //         return <></>;
            //     }

            //     // return <>
            //     //     <Table.Summary.Row>
            //     //         <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={1}></Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={2}>
            //     //             <Typography.Text>{avgMC.toFixed(2)}</Typography.Text>
            //     //         </Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={3}>
            //     //             <Typography.Text>{totalBushelsEntered.toFixed(0)}</Typography.Text>
            //     //         </Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={4}></Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={5}></Table.Summary.Cell>
            //     //     </Table.Summary.Row>
            //     // </>
            // }}
        >
            <Column width="10ch" title="Load Time" dataIndex="loadTime" key="loadTime" />
            <Column width="10ch" title="Event Type" dataIndex="eventType" key="eventType" responsive={['md']} />
            <Column width="12ch" title="Moisture Content" dataIndex="MC" key="MC" />
            <Column width="8ch" title="Estimated Bu" dataIndex="estimatedBu" key="estimatedBu" />
            {/* <Column title="Estimated Height" dataIndex="height" key="height" /> */}
            <Column width="8ch" title="Source Or Destination" dataIndex="srcOrDest" key="srcOrDest" />
            <Column width="6ch"
                title="Action"
                dataIndex="action"
                render={(val, record: Load) => <PopconfirmYesNo
                    title="Delete This Load?"
                    onConfirm={() => props.deleteLoad(record)}>
                    <Button icon={<DeleteOutlined />} danger type="primary" ghost={true}
                        disabled={props.hasPendingLoadDeletes()}
                        loading={props.pendingLoadDeletes[record.key] ?? false}>
                        Delete
                    </Button>
                </PopconfirmYesNo>} />
        </Table>
    );
}


export const FillLoadTable = (props: LoadTableProps) => {
    const { totalBushels, avgMC } = useMemo(() => calcAvgMC(props.loadData), [props.loadData]);
    return (
        <Table bordered={true} size="small" dataSource={props.loadData} pagination={{ position: ["topLeft", "bottomRight"], pageSize: 10 }}
            // summary={(_) => {
            //     const totalBushelsEntered = totalBushels;
            //     if (totalBushelsEntered == null || avgMC == null) {
            //         return <></>;
            //     }

            //     // return <>
            //     //     <Table.Summary.Row>
            //     //         <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={1}></Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={2}>
            //     //             <Typography.Text>{avgMC.toFixed(2)}</Typography.Text>
            //     //         </Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={3}>
            //     //             <Typography.Text>{totalBushelsEntered.toFixed(0)}</Typography.Text>
            //     //         </Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={4}></Table.Summary.Cell>
            //     //         <Table.Summary.Cell index={5}></Table.Summary.Cell>
            //     //     </Table.Summary.Row>
            //     // </>
            // }}
        >
            <Column width="10ch" title="Load Time" dataIndex="loadTime" key="loadTime" />
            <Column width="10ch" title="Event Type" dataIndex="eventType" key="eventType" responsive={['md']} />
            <Column width="12ch"
                title="Moisture Content"
                dataIndex="MC"
                key="MC" />
            <Column width="8ch" title="Estimated Bu" dataIndex="estimatedBu" key="estimatedBu" />
            {/* <Column title="Estimated Height" dataIndex="height" key="height" /> */}
            <Column width="8ch" title="Source Or Destination" dataIndex="srcOrDest" key="srcOrDest" responsive={['lg']} />
            <Column width="6ch"
                title="Action"
                dataIndex="action"
                render={(val, record: Load) => <PopconfirmYesNo
                    title="Delete This Load?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => props.deleteLoad(record)}>
                    <Button icon={<DeleteOutlined />} type="primary" danger ghost={true}
                        disabled={props.hasPendingLoadDeletes()}
                        loading={props.pendingLoadDeletes[record.key] ?? false}>
                        Delete
                    </Button>
                </PopconfirmYesNo>} />
        </Table>
    );
}