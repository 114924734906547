import { Dispatch } from 'redux';
import BaseAction from './BaseAction';
import History from 'src/utils/HistoryUtil';
import AccountApiService from 'src/api/AccountApiService';
import LoginDTO from 'src/models/LoginDTO';
import UserSecurityDTO from 'src/models/UserSecurityDTO';
import UserSecurity from 'src/models/UserSecurity';
import Routes from 'src/consts/Routes';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { queryClient } from 'src';

export type UserSessionAction = BaseAction<UserSessionActionTypes, any>;

export enum UserSessionActionTypes {
    USER_SESSION_CHECKING = 'USER_SESSION_CHECKING',
    USER_SESSION_CHECK = 'USER_SESSION_CHECK',
    USER_SESSION_LOADING = 'USER_SESSION_LOADING',
    USER_SESSION_LOGIN = 'USER_SESSION_LOGIN',
    USER_SESSION_LOGIN_ERROR = 'USER_SESSION_LOGIN_ERROR',
    USER_SESSION_LOGOUT = 'USER_SESSION_LOGOUT',
    USER_SESSION_CHANGED = 'USER_SESSION_CHANGED',
    USER_GROWER_CHANGED = 'USER_GROWER_CHANGED'
}

export const UserSessionActions = {
    login: UserSessionLoginAction,
    logout: UserSessionLogoutAction,
    check: UserSessionCheckAction,
    refresh: UserSessionRefreshAction,
    changeGrower: UserGrowerCheckedAction
};

function UserSessionLogoutAction() {
    return (dispatch: Dispatch<any>) => {
        AccountApiService.logout()
            .then(() => {
                dispatch(UserSessionChangedAction(null));
            })
            .catch((results) => {
                dispatch(UserSessionLoadingAction(false));
            })
            .then(() => { // .finally()
                queryClient.clear();
                History.push(Routes.LOGIN);
            });
    };
}

function UserSessionLoginAction(model: LoginDTO) {
    return (dispatch: Dispatch<any>) => {
        dispatch(UserSessionLoadingAction(true));
        AccountApiService.login(model)
            .then(() => {
                _UserSessionCheckAction(dispatch, true);
            })
            .catch((results) => {
                dispatch(UserSessionLoginErrorAction(results));
                dispatch(UserSessionLoadingAction(false));
            })
            .then(() => { // .finally()
            });
    };
}

function UserSessionCheckAction() {
    return (dispatch: Dispatch<any>, getState: () => StateStoreModel) => {
        let current = getState().UserSession;
        if (current.Value) {
            dispatch(UserSessionChangedAction(current.Value));
            return;
        }
        dispatch(UserSessionCheckingAction(true));
        _UserSessionCheckAction(dispatch, false);
    };
}

function _UserSessionCheckAction(dispatch: Dispatch<any>, isLoading: boolean) {
    let userAction = isLoading ? UserSessionLoadingAction : UserSessionCheckingAction;
    AccountApiService.getCurrentUser()
        .then((results: UserSecurityDTO | null) => {
            if (results) {
                dispatch(UserSessionChangedAction(new UserSecurity(results)));
            } else {
                dispatch(UserSessionChangedAction(null));
            }
            dispatch(userAction(false));
        }).catch((results) => {
            dispatch(userAction(false));
        });
}

function UserSessionRefreshAction(newUserSession: UserSecurityDTO | null) {
    return (dispatch: Dispatch<any>) => {
        // Start session loading
        if (newUserSession) {
            dispatch(UserSessionChangedAction(new UserSecurity(newUserSession)));
        } else {
            dispatch(UserSessionChangedAction(null));
        }
    };
}

function UserSessionLoginErrorAction(error: any): UserSessionAction {
    return {
        type: UserSessionActionTypes.USER_SESSION_LOGIN_ERROR,
        data: error
    };
}

function UserSessionCheckingAction(isChecking: boolean): UserSessionAction {
    return {
        type: UserSessionActionTypes.USER_SESSION_CHECKING,
        data: isChecking
    };
}

function UserSessionLoadingAction(isLoading: boolean): UserSessionAction {
    return {
        type: UserSessionActionTypes.USER_SESSION_LOADING,
        data: isLoading
    };
}

function UserSessionChangedAction(model?: UserSecurity | null | undefined): UserSessionAction {
    return {
        type: UserSessionActionTypes.USER_SESSION_CHANGED,
        data: model
    };
}

function UserGrowerCheckedAction(grower: number | null) {
    return (dispatch: Dispatch<any>) => {
        dispatch(UserGrowerChangedAction(grower));
        // History.push({
        //     pathname: `/`,
        // });
    };
}

function UserGrowerChangedAction(grower: number | null): UserSessionAction {
    return {
        type: UserSessionActionTypes.USER_GROWER_CHANGED,
        data: grower
    };
}
