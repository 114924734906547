import UserSecurityDTO from './UserSecurityDTO';

export default class UserSecurity {
    public readonly firstName: string | null;
    public readonly lastName: string | null;
    public readonly userId: number;
    public readonly userName: string | null;
    private readonly roles: ReadonlyArray<UserSecurityDTO['roles'][0]>;

    constructor(userinfo: UserSecurityDTO) {
        this.firstName = userinfo.firstName;
        this.lastName = userinfo.lastName;
        this.userId = userinfo.userId;
        this.userName = userinfo.userName;
        this.roles = Object.freeze(userinfo.roles);
    }

    isInRole(role: UserSecurity['roles'][0]) {
        return this.roles.indexOf(role) >= 0;
    }

    getRoles() {
        return this.roles.slice(0);
    }
}
