import Role from 'src/consts/Role';
import UserSecurityDTO from 'src/models/UserSecurityDTO';
import CurrentUser from './CurrentUser';
export default class RoleUtil {
    
    public static currentUserName(): string{

        return CurrentUser.Get()?.userName ?? "N/A";
    }

    public static currentUserisAtLeastPowerUser(): boolean {
        const roles = [Role.ADMIN, Role.POWERUSER, Role.SUPPORT];
        const atLeastPowerUser = RoleUtil.currentUserHasAnyOfRoles(roles);
        return atLeastPowerUser;

    }
    public static currentUserIsAdmin(): boolean {
        const roles = [Role.ADMIN];
        const isAdmin = RoleUtil.currentUserHasAnyOfRoles(roles);
        return isAdmin;
    }
    public static currentUserHasAnyOfRoles(roles: UserSecurityDTO['roles']): boolean {
        let currentUser = CurrentUser.Get();

        if (currentUser) {
            for (let role of roles) {
                if (currentUser.isInRole(role)) {
                    return true;
                }
            }
        }

        return false;
    }
}
