import * as React from 'react';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import EnterpriseDTO from 'src/models/EnterpriseDTO';
import SiteDTO from 'src/models/SiteDTO';
import {
    Col,
    Layout,
    notification,
    Row,
    Spin,
    Result
} from 'antd';
//import '@ant-design/compatible/assets/index.css';
import BinDetails from './BinDetails';
import BinInfoDTO from 'src/models/BinInfoDTO';
import GrowerDTO from 'src/models/GrowerDTO';
import { RouteComponentProps } from 'react-router-dom';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { connect } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { binDBKeys } from 'src/pages/binOverview/BinCommander';
import { useQueryClient } from '@tanstack/react-query';
import { PageHeader } from '@ant-design/pro-layout';
import { ExtraOfflineInfoContext } from '../BinOfflineIndicator';

interface HomeProps {
    growerID: number | null;
}

interface State {
    model: SiteDTO;
    loading: boolean;
    enterprise: EnterpriseDTO;
    currentGrower: number | null;
    grower?: GrowerDTO;
    homeTitle: string | null;
    visible: boolean;
    siteName: string;
    location: string;
    targetValue: any;
    targetValidate: '' | 'error' | 'success' | 'warning' | 'validating' | undefined;
    offline: boolean;
    showExtraOfflineInfoForBins: boolean,
}

const Home = (props: RouteComponentProps & HomeProps) => {
    const queryClient = useQueryClient();

    const initialstate: State = {
        model: SiteDTO.create(),
        loading: true,
        enterprise: EnterpriseDTO.create(),
        homeTitle: 'Bin Overview',
        visible: false,
        siteName: '',
        location: '',
        targetValue: null,
        targetValidate: '',
        currentGrower: props.growerID,
        offline: false,
        showExtraOfflineInfoForBins: false,
    };
    const [state, setState] = useState<State>(initialstate);

    const fetchData = useCallback((growerID: number | null) => {
        if (growerID) {
            setState((prev) => { return { ...prev, loading: true }; });
            EnterpriseApiService.getEnterpriseDetail(growerID).then(enterprise => {
                // pass db data to server cache of individual bin binInfo
                enterprise?.grower?.bins?.forEach((bin) => {
                    queryClient.setQueryData(binDBKeys.binInfo(bin.id), (oldData: BinInfoDTO | undefined) => {
                        if (bin?.id == null) {
                            return undefined;
                        }
                        return bin;
                    });
                });
                setState((prev) => {
                    return {
                        ...prev,
                        enterprise: enterprise,
                        grower: enterprise.grower!,
                        targetValue: enterprise.grower?.cornTargetPrice,
                        loading: false
                    };
                }
                );
            }).catch(error => {
                setState((prev) => { return { ...prev, loading: false, offline: true }; });
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }
    }, [queryClient]);

    useEffect(() => {
        fetchData(props.growerID);
    }, [props.growerID]);

    const setLoading = useCallback((val: boolean, i: number) => {
        setState((prev) => { return { ...prev, loading: val }; });
    }, [setState]);

    const getBins = (binLength: number, bins: BinInfoDTO[] | null | undefined) => {
        if (bins) {
            return (
                bins.map((bin: BinInfoDTO, i: number) => (
                    <BinDetails
                        bin={bin}
                        offlineReport={null}
                        binLength={binLength}
                        targetValue={state.targetValue}
                        key={bin.id}
                        id={i}
                        isLoading={setLoading} />
                )));
        } else {
            return <></>;
        }

    }

    const { offline } = state;
    let binLength = state.grower?.bins?.length || 0;

    if (offline) {
        return (
            <Layout.Content className="dashboard" style={{ height: '100%' }}>

                <PageHeader
                    title={state.homeTitle}
                    style={{ width: '100%' }} />

                <Row align="middle">
                    <Col span={24} style={{ width: '100%' }}>
                        <Result status="warning" title="Error Getting Bin Overview" />
                    </Col>
                </Row>

            </ Layout.Content>
        );
    } else {

        return (
            <Layout.Content className="dashboard" >

                <Row>
                    <PageHeader
                        className="formButton"
                        title={state.homeTitle}
                        style={{ width: '100%', paddingRight: 0, paddingLeft: 0 }}
                    />
                </Row>
                <Spin spinning={state.loading} style={{ paddingBottom: 400, height: '100%' }} tip="loading...">
                    <ExtraOfflineInfoContext.Provider value={{showExtraOfflineInfo: state.showExtraOfflineInfoForBins, toggleOfflineInfo: () => setState({...state, showExtraOfflineInfoForBins: !state.showExtraOfflineInfoForBins})}}>
                    <Row style={{ paddingBottom: state.loading ? 300 : 20 }} align="top" gutter={{ xs: 8, sm: 8, md: 16, lg: 16, xl: 16 }}>
                        {getBins(binLength, state.grower?.bins)}
                    </Row>
                    </ExtraOfflineInfoContext.Provider>

                </Spin>

            </ Layout.Content>
        );
    }

};


function mapStateToProps(state: StateStoreModel) {
    return {
        growerID: state.UserSession.GrowerID
    };
}

export default connect(mapStateToProps, null)(Home);
