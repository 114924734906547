import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Form, message, Popconfirm, Radio, Row, Space, Tag, theme, Tooltip, Typography } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { ApiError } from "src/api/ApiResultHandler";
import BinApiService from 'src/api/BinApiService';
import RoutineType from "src/consts/RoutineType";
import BinDTO from "src/models/BinDTO";
import StackDTO from "src/models/StackDTO";
import { ObjectColorInfo, OperatingMode } from "./BinVisualThree";
import { DryLayerAdjust, GrainLayerSummaryInfo, LayerFormat } from "./DryLayerAdjust";
import { ValveDisplay } from "./ValveDisplay";
import RoleUtil from "src/utils/RoleUtil";
import Role from "src/consts/Role";
import ValvePositionDTO from "src/models/ValvePositionDTO";
import _ from "lodash";
import { deviceQueryKeys } from "../HomeScreen/BinDetails";
import { RedoOutlined } from "@ant-design/icons";
import { NewValveDisplay, useValveDisplay } from "./NewValveDisplay";
import { GrainCoveredDot } from "./NewValveDisplay";
import { useTurnOnCompressorMutation } from "./CompressorControls";

export const useRunManaulLayerMutation = (deviceId: string) => {

    return useMutation({
        mutationFn: (variables: { targetLayer: number }) => {
            return BinApiService.runManualLayer(deviceId, variables.targetLayer);
        }
    });
}

export const useRunCoreManualRoutineMutation = (deviceId: string) => {

    return useMutation({
        mutationFn: async () => {
            return await BinApiService.runCoreManualRoutine(deviceId);
        }
    });
}


export const useCloseAllValvesAndClearRoutinesMutation = (deviceId: string) => {

    return useMutation({
        mutationFn: () => {
            return BinApiService.closeAllValvesAndClearRoutines(deviceId);
        }
    });
}

interface ManualRoutineContolProps {
    updateValves?: (updates: StackDTO[]) => void;
    highlightArr: ObjectColorInfo[];
    highlightStack: (stackId: string) => void;
    deviceId: string,
    binDTO: BinDTO,
    binId: number,
}

enum ManualRoutineVariations {
    Closed = "Closed",
    Individual = "Individual",
    Core = "Core",
    TargetLayer = "TargetLayer",
    Custom = "Custom",
}

const StatusText = (props: { manualRoutineType: ManualRoutineVariations | null, binDTO: BinDTO }) => {
    if (props.manualRoutineType === ManualRoutineVariations.Closed) {
        return <Typography.Text>Status: Valves Closed</Typography.Text>;
    }
    if (props.manualRoutineType === ManualRoutineVariations.Core) {
        return <Typography.Paragraph>Status: Targeting the Core 
            {/* < br /> <Typography.Text>Layer: {props.binDTO?.routineEngine?.targetLayer ?? <RedoOutlined spin />}</Typography.Text> */}
            </Typography.Paragraph>
    }
    if (props.manualRoutineType === ManualRoutineVariations.Individual) {
        return <Typography.Text>Status: Individual Valves</Typography.Text>
    }
    if (props.manualRoutineType === ManualRoutineVariations.TargetLayer) {
        return <Typography.Text>Status: Targeting layer {props.binDTO?.routineEngine?.targetLayer ?? <RedoOutlined spin />}</Typography.Text>
    }
    if (props.binDTO?.desiredProperties?.isSeedBin) {
        return <Typography.Paragraph>Status: Custom Routine <br /> <Typography.Text>Name: {props.binDTO?.currentRoutineName}</Typography.Text></Typography.Paragraph>
    }
    return null;
}

export const useSetValvesMutation = (deviceId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { desiredValveChanges: Array<StackDTO> }) => {
            const valvePositionUpdates = _.flatten(variables.desiredValveChanges
                .map(x => x.valves!.map(y => ({ valveId: y.id, position: y.positionLabel })))) as ValvePositionDTO[];
            const result = await BinApiService.setValvePositions(deviceId, valvePositionUpdates);
            return result;
        },
        onSettled: async (data, error, variables, context) => {
            await queryClient.invalidateQueries(deviceQueryKeys.stateFromAzure(deviceId));
            await queryClient.invalidateQueries(deviceQueryKeys.stateFromDevice(deviceId));
        },
    })
}

export const ManualRoutineControl = (props: ManualRoutineContolProps) => {
    const queryClient = useQueryClient();
    const binDTO = props.binDTO;
    const [formIteration, setFormIteration] = useState(0);
    const { token } = theme.useToken();
    const [form] = useForm();

    const setValvesMutation = useSetValvesMutation(props.deviceId);

    const [openCloseAllValvesConfirm, setOpenCloseAllValvesConfirm] = useState(false);
    const [openTargetCoreConfirm, setOpenTargetCoreConfirm] = useState(false);
    const [openTargetCoreTooltip, setOpenTargetCoreTooltip] = useState(false);

    const updateValves = async (desiredValveChanges: StackDTO[]) => {

        setValvesMutation.mutate({ desiredValveChanges }, {
            onSuccess(data, variables, context) {
                if (data === true) {
                    message.success('Valve configuration changed. Watch Refresh button for update.');
                }
                else if (data === false) {
                    message.error('Failed to update valve configuration in all/part. Make sure system is in User Control mode');
                }
                else {
                    console.error("Unexpected valve response gotten: ", data);
                    message.error("valve update failed with unknown response: contact support");
                }
            },
            onError(error, variables, context) {
                console.error("error updating valve configuration", error);
                message.error('Failed to update valve configuration.');

            },
        });
    }

    const currentValues = (binDTO: BinDTO) => {
        if (binDTO?.desiredProperties?.isSeedBin && binDTO.routineEngine?.routine === RoutineType.Custom) {
            return ManualRoutineVariations.Custom;
        }
        if (binDTO.routineEngine?.anyStaticValvePositions === false && binDTO?.manualRoutineSettings?.isManualRoutineSet === false) {
            return ManualRoutineVariations.Closed;
        }
        if (binDTO.manualRoutineSettings?.isManualRoutineSet && binDTO?.manualRoutineSettings?.targetLayer) {
            return ManualRoutineVariations.TargetLayer;
        }
        const manualRoutineType = binDTO.manualRoutineSettings?.type;
        if (binDTO.manualRoutineSettings?.isManualRoutineSet && [RoutineType.Core].includes(manualRoutineType!)) {
            return ManualRoutineVariations.Core;
        }
        if (binDTO?.routineEngine?.anyStaticValvePositions) {
            return ManualRoutineVariations.Individual;
        }
        return null;
    }
    const routineValue = currentValues(props.binDTO);

    useEffect(() => {
        if (form.isFieldsTouched()) {
            return;
        }
        const routineValue = currentValues(props.binDTO);
        const targetLayer = props.binDTO?.manualRoutineSettings?.targetLayer;
        form.setFields([{ name: 'routineSetting', touched: false, value: routineValue },
        { name: "targetLayer", touched: false, value: targetLayer },
        ]);
        setFormIteration(val => val + 1);
    }, [props.binDTO, form]);

    const manualRoutineSetting = useWatch([], form);

    const targetLayer = props.binDTO?.routineEngine?.targetLayer;
    const closeAllValvesMutation = useCloseAllValvesAndClearRoutinesMutation(props.deviceId);
    const runCoreManualRoutineMutation = useRunCoreManualRoutineMutation(props.deviceId);
    const runManaulLayerMutation = useRunManaulLayerMutation(props.deviceId);
    const turnOnCompressorMutation = useTurnOnCompressorMutation(props.deviceId);

    const inManualMode = props.binDTO?.operatingMode === OperatingMode.Manual;
    const isSeedBin = props.binDTO?.desiredProperties?.isSeedBin === true;


    const allowValveEdits = () => {

        if (setValvesMutation.isLoading) {
            return false;
        }

        if (props.binDTO?.desiredProperties?.isSeedBin === true) {
            return true;
        }

        if (manualRoutineSetting?.routineSetting != ManualRoutineVariations.Individual) {
            return false;
        }
        return true;
    }

    const showDesiredValveSelection = (): boolean => {
        if (props.binDTO?.desiredProperties?.isSeedBin === true) {
            return true;
        }
        if (manualRoutineSetting?.routineSetting === ManualRoutineVariations.Individual) {
            return true;
        }
        return false;
    }

    const showIndividualValveView = (): boolean => {
        const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);
        if (isAdmin) {
            return true;
        }
        if (!inManualMode) {
            return true;
        }
        if (manualRoutineSetting?.routineSetting != ManualRoutineVariations.Individual) {
            return false;
        }
        return true;
    }

    const submitTargetLayer = async () => {


        let targetLayer = Number.parseInt(form.getFieldValue('targetLayer'), 10);

        let compressorRequestSucceeded = false;
        try {
            const result = await turnOnCompressorMutation.mutateAsync();
            if (result.success === true) {
                compressorRequestSucceeded = true;
            }
            else {
                message.error("Problem turning on compressor");
            }
        } catch (error) {
            console.log("error turning on compressor", error);
            message.error(`Error turning on compressor`);
        }
        if (!compressorRequestSucceeded) {
            return;
        }

        runManaulLayerMutation.mutate({ targetLayer: targetLayer, },
            {
                onSuccess(data, variables, context) {
                    setFormIteration(val => val + 1);
                    if (data.success) {
                        message.success(`Targeting layer ${variables.targetLayer}`);
                        form.setFields([{ name: 'targetLayer', touched: false }]);
                    }
                    else {
                        message.error(`Problem targeting layer ${variables.targetLayer}`);
                    }
                },
                onError(error, variables, context) {
                    console.error("error setting layer target", error, { error, variables });
                    message.error(`Error setting layer target to ${variables.targetLayer}`);
                },
            }
        );
    }

    const valveDisplayInstance = useValveDisplay();


    return (
        <React.Fragment>
            <Form key={formIteration} form={form} initialValues={{
                routineSetting: routineValue,
                targetLayer: targetLayer,
            }}>
                {props.binDTO?.operatingMode === OperatingMode.Manual && <Row>
                    <Col span={16}>
                        <Form.Item name="routineSetting">
                            <Radio.Group buttonStyle="solid" onChange={(event) => {
                                valveDisplayInstance.clearAllDesiredValves({ clearSelections: true, clearFailedValves: true });
                                // close the popup that isn't part of the selection
                                if (event.target.value !== ManualRoutineVariations.Closed) {
                                    setOpenCloseAllValvesConfirm(false);
                                }
                                if (event.target.value !== ManualRoutineVariations.Core) {
                                    setOpenTargetCoreConfirm(false);
                                    setOpenTargetCoreTooltip(false);
                                }

                                // close all tooltips
                            }}>
                                <Popconfirm title="Close All Valves?"
                                    open={openCloseAllValvesConfirm}
                                    onOpenChange={(newOpen) => {
                                        // ignored to force pressing either cancel or close (clicking outside for some reason doesn't count as cancelling...)
                                    }}
                                    onCancel={() => {
                                        setFormIteration(count => count + 1);
                                        form.resetFields();
                                        setOpenCloseAllValvesConfirm(false);
                                    }}
                                    onConfirm={() => {
                                        setOpenCloseAllValvesConfirm(false);
                                        closeAllValvesMutation.mutate(undefined, {
                                            onSuccess(data, variables, context) {
                                                if (data.success) {
                                                    message.info("Valves are set to close");
                                                } else {
                                                    message.error(`Problem requesting all valves to close`);
                                                }
                                            },
                                            onError(error, variables, context) {
                                                console.error("error requesting all valves and routine to close/clear", error);
                                                const errorUserText = "Error requesting all valves to close";
                                                if (error instanceof ApiError) {
                                                    console.error("Error requesting all valves to close", error);
                                                }
                                                else {
                                                    message.error(errorUserText);
                                                }

                                            },
                                        });
                                    }}
                                >
                                    <Radio.Button onClick={() => {
                                        // close the other popups
                                        setOpenTargetCoreConfirm(false);
                                        setOpenTargetCoreTooltip(false);

                                        setOpenCloseAllValvesConfirm(true);
                                    }} disabled={closeAllValvesMutation.isLoading} value={ManualRoutineVariations.Closed}>Closed</Radio.Button>
                                </Popconfirm>
                                <Tooltip title="Set the position of individual valves">
                                    <Radio.Button value={ManualRoutineVariations.Individual}>Individual Valves</Radio.Button>
                                </Tooltip>
                                <Tooltip title="Valves are configured to direct airflow to the bin core" open={openTargetCoreTooltip && !openTargetCoreConfirm} onOpenChange={(newVisible => {
                                    setOpenTargetCoreTooltip(newVisible);
                                })} >
                                    <Popconfirm title="Target The Core?" description="The compressor will automatically turn on to control the valves."
                                        open={openTargetCoreConfirm}
                                        onOpenChange={(newOpen) => {
                                            // ignored to force pressing either cancel or close (clicking outside for some reason doesn't count as cancelling...)
                                            // there has to be a way to track clicking outside the popup....
                                        }}
                                        onCancel={() => {
                                            setFormIteration(count => count + 1);
                                            form.resetFields();
                                            // force the tooltip closed if it was going to be open
                                            setOpenTargetCoreTooltip(false);
                                            setOpenTargetCoreConfirm(false);
                                        }}
                                        onConfirm={async () => {
                                            // force the tooltip closed if it was going to be open
                                            setOpenTargetCoreTooltip(false);
                                            setOpenTargetCoreConfirm(false);

                                            let compressorRequestSucceeded = false;
                                            try {
                                                const result = await turnOnCompressorMutation.mutateAsync();
                                                if (result.success === true) {
                                                    compressorRequestSucceeded = true;
                                                }
                                                else {
                                                    message.error("Problem turning on compressor");
                                                }
                                            } catch (error) {
                                                console.log("error turning on compressor", error);
                                                message.error(`Error turning on compressor`);
                                            }
                                            if (!compressorRequestSucceeded) {
                                                return;
                                            }

                                            runCoreManualRoutineMutation.mutate(undefined, {
                                                onSuccess(data, variables, context) {
                                                    if (data.success) {
                                                        message.info('Core routine set to run');
                                                    } else {
                                                        message.error("Problem requesting Core routine to run");
                                                    }
                                                },
                                                onError(error, variables, context) {
                                                    console.error("Error requesting core routine to run", error);
                                                    message.error("Errir requesting Core routine to run");
                                                },
                                            });
                                        }}
                                    >
                                        <Radio.Button onClick={() => {
                                            // close the other popups
                                            setOpenCloseAllValvesConfirm(false);

                                            setOpenTargetCoreConfirm(true);
                                        }} disabled={runCoreManualRoutineMutation.isLoading || isSeedBin || turnOnCompressorMutation.isLoading} value={ManualRoutineVariations.Core}>Target the Core</Radio.Button>
                                    </Popconfirm>
                                </Tooltip>
                                <Tooltip title="Target a layer with airflow">
                                    <Radio.Button value={ManualRoutineVariations.TargetLayer} disabled={isSeedBin}>Target by Layer</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={8} style={{ justifyContent: "end", width: "100%", display: "flex" }}>
                        <StatusText manualRoutineType={routineValue} binDTO={props.binDTO} />
                    </Col>
                </Row>}

                {manualRoutineSetting?.routineSetting === ManualRoutineVariations.Core && <>
                    <Typography.Text>Valves are configured to direct airflow to the bin core.</Typography.Text>
                </>}

                {manualRoutineSetting?.routineSetting === ManualRoutineVariations.Individual && <>
                    <Typography.Text>The compressor will automatically turn on to control the valves.</Typography.Text>
                </>}

                <Form.Item name="targetLayer" shouldUpdate noStyle>
                    {manualRoutineSetting?.routineSetting == ManualRoutineVariations.TargetLayer && <div style={{ paddingTop: '16px' }}>
                        <Typography.Title level={4} >Layer Target Settings</Typography.Title>
                        <Typography.Text>Select a layer to target with airflow. The compressor will automatically turn on to control the valves.</Typography.Text>
                        <div style={{ paddingTop: "16px" }}>
                            {[...props.binDTO?.layers ?? []].reverse().map(layer => {

                                const targetlayerSelected = manualRoutineSetting?.targetLayer === layer.number;
                                const submitted = !form.isFieldTouched('targetLayer');

                                return (<Card key={layer.number} title="" style={{
                                    ...(targetlayerSelected ? {
                                        backgroundColor: token.colorPrimary, color: "white",
                                    } : undefined),
                                }} onClick={() => {
                                    form.setFields([{ name: 'targetLayer', touched: true, value: layer.number }])
                                }}>
                                    <Row align="middle">
                                        <Col xs={9}>
                                            <LayerFormat binDTO={props.binDTO} layerNumber={layer.number} />
                                        </Col>
                                        <Col xs={12}>
                                            <GrainLayerSummaryInfo binDTO={props.binDTO} layerNumber={layer.number} />
                                        </Col>
                                        <Col xs={3}>
                                            {targetlayerSelected && !submitted && <Tag color="red-inverse" onClick={submitTargetLayer}>Click Apply</Tag>}
                                        </Col>
                                    </Row>
                                </Card>);
                            })}
                        </div>
                        <div style={{ paddingTop: "16px" }} />
                        <Row justify={'end'}>
                            <Col>
                                <Button type="primary" loading={runManaulLayerMutation.isLoading || turnOnCompressorMutation.isLoading} disabled={runManaulLayerMutation.isLoading || turnOnCompressorMutation.isLoading || manualRoutineSetting.targetLayer == null} onClick={submitTargetLayer}>Apply</Button>
                            </Col>
                        </Row>
                    </div>
                    }
                </Form.Item>
            </Form>
            <div style={{ paddingTop: "16px" }} />

            {showIndividualValveView() && (
                <Row>
                    <Col>
                        <Typography.Title level={5}>
                            Current Valve Positions {!allowValveEdits() ? "(View only)" : ""}
                            {valveDisplayInstance.hasValvesToSubmit ? (
                            <Typography.Text type="danger" strong>(Not Applied)</Typography.Text>
                            ) : null}
                        </Typography.Title>
                    </Col>
                    <Col style={{paddingRight:"10px", paddingLeft:"10px"}}> {<GrainCoveredDot covered={true} inIcon={false}/>}</Col>
                    <Col> Covered</Col>
                    <Col style={{paddingRight:"10px", paddingLeft:"10px"}}>{<GrainCoveredDot covered={false} inIcon={false}/>}</Col>
                    <Col> Not Covered</Col>
                    <Col span={24}>
                        <NewValveDisplay
                            binDTO={props.binDTO!}
                            azureDeviceId={props.deviceId!}
                            binId={props.binId!}
                            allowEdits={allowValveEdits()}
                            valveDisplayInstance={valveDisplayInstance}
                        />
                    </Col>
                </Row>
            )}

        </React.Fragment>
    );
}