import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Select, Form, Spin } from 'antd';
import BoardGroup from 'src/consts/BoardGroup';
import PCBLookupDTO from 'src/models/PCBLookupDTO';
import DeviceProfileDTO from 'src/models/DeviceProfileDTO';
import { useQuery } from "@tanstack/react-query";
import { FormInstance } from 'antd/lib';
import { useSetState } from 'rooks';
import BinAssemblyDTO from 'src/models/BinAssemblyDTO';
// import { createBinAssemblyFromExcelSheet } from './CreateAssemblyFromExcelSheetV2';


const { Option } = Select;

export interface DeviceTableProps {
    deviceProfiles: DeviceProfileDTO[];
    pcbLookups: PCBLookupDTO[];
    onUpdateDevices: (binAssembly: DeviceProfileDTO[]) => void;

}

export interface DeviceForm {
    boardType: BoardGroup;
}

export const DeviceProfileTable: React.FC<DeviceTableProps> = ({ onUpdateDevices, deviceProfiles, pcbLookups}) => {
    const [addEntryModalVisible, setAddEntryModalVisible] = useState<boolean>(false);
    const [form] = Form.useForm<DeviceForm>();
    const values = Form.useWatch(['boardType'], {form, preserve: true});
    const[sortedDevProfiles, setSortedDevProfiles] = useState<DeviceProfileDTO[]>([...deviceProfiles].sort( (a,b) => a.canID - b.canID));

    const initialHubCount = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.Hub).length;
    const initialFanCount = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.Fans).length;
    const initialStackCount =deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.StackBoards).length;
    const initalshbCount = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.SensingBoards).length;
    const initalRhtcount = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.RHTBoards).length;
    const initialOpiCount = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.OPIBoards).length;

    const [hubCount, setHubCount] =     useState<number>(initialHubCount);
    const [fanCount, setFanCount] =     useState<number>(initialFanCount);
    const [stackCount, setStackCount] = useState<number>(initialStackCount);
    const [shbCount, setShbCount] =     useState<number>(initalshbCount);
    const [rhtCount, setRhtCount] =     useState<number>(initalRhtcount);
    const [opiCount, setOpiCount] =     useState<number>(initialOpiCount);

    useEffect( () => {
        setSortedDevProfiles([...deviceProfiles].sort( (a,b) => a.canID - b.canID));

        //reset fan can ids
        let i =1;
        let hubBoards = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.Hub);
        hubBoards.forEach( b=> {
            let boardId = b.boardLookupDTO?.baseCanID! +i;
            b.canID = boardId;
            b.boardNumber = i;
            i++;
        })
        setHubCount(hubBoards.length)

        //reset fan can ids
        i =1;
        let fanBoards = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.Fans);
        fanBoards.forEach( b=> {
            let boardId = b.boardLookupDTO?.baseCanID! +i;
            b.canID = boardId;
            b.boardNumber = i;
            b.name = `${b.boardLookupDTO?.initials}${i}`;
            i++;
        })
        setHubCount(fanBoards.length)

        //reset stb can ids
        i =1;
        let stackBoards = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.StackBoards);
        stackBoards.forEach( b=> {
            let boardId = b.boardLookupDTO?.baseCanID!+i;
            b.canID = boardId;
            b.boardNumber = i;
            b.name = `${b.boardLookupDTO?.initials}${i}`;
            i++;
        })
        setStackCount(stackBoards.length);

        //reset shb can ids
        i =1;
        let sensingBoards = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.SensingBoards
        );
        sensingBoards.forEach( b=> {
            let boardId = b.boardLookupDTO?.baseCanID!+i;
            b.canID = boardId;
            b.boardNumber = i;
            b.name = `${b.boardLookupDTO?.initials}${i}`;
            i++;
        })
        setShbCount(sensingBoards.length);

        //reset rhtb can ids
        i =1;
        let rhtBoards = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.RHTBoards);
        deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.RHTBoards).forEach( b=> {
            let boardId = b.boardLookupDTO?.baseCanID!+i;
            b.canID = boardId;
            b.boardNumber = i;
            b.name = `${b.boardLookupDTO?.initials}${i}`;
            i++;
        })
        setRhtCount(rhtBoards.length);

        //reset shb can ids
        i =1;
        let opiBoard = deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.OPIBoards);
        deviceProfiles.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.OPIBoards).forEach( b=> {
            let boardId = b.boardLookupDTO?.baseCanID!+i;
            b.canID = boardId;
            b.boardNumber = i;
            b.name = `${b.boardLookupDTO?.initials}${i}`;
            i++;
        })
        setOpiCount(opiBoard.length);
        
    },[deviceProfiles])

    const getBoardId = (lookup: PCBLookupDTO) => {
        switch(lookup.boardGroupId){
            case BoardGroup.Hub:
                return lookup.baseCanID + hubCount+1;
            case BoardGroup.Fans:
                return lookup.baseCanID+ fanCount+1;
            case BoardGroup.StackBoards:
                return lookup.baseCanID + stackCount+1;
            case BoardGroup.SensingBoards:
                return lookup.baseCanID+ shbCount+1;
            case BoardGroup.RHTBoards:
                return lookup.baseCanID+ rhtCount+1
            case BoardGroup.OPIBoards:
                return lookup.baseCanID+ opiCount+1
            default:
                 return 0xFFF;
        }
    }

    const getBoardName = (lookup: PCBLookupDTO) => {
        let num = 0;
        switch(lookup.boardGroupId){
            case BoardGroup.Hub:
                num=hubCount+1;
                break;
            case BoardGroup.Fans:
                num=fanCount+1;
                break;
            case BoardGroup.StackBoards:
                num=stackCount+1;
                break;
            case BoardGroup.SensingBoards:
                num=shbCount+1;
                break;
            case BoardGroup.RHTBoards:
                num=rhtCount+1;
                break;
            case BoardGroup.OPIBoards:
                num=opiCount+1;
                break;
            default:
                num=0;
        }

        let retString = `${lookup.initials}${num}`
        return retString
    }

    const handleDelete = (record: DeviceProfileDTO) => {

        // Filter out the record to be deleted
        const updatedDeviceProfiles = deviceProfiles.filter(profile => profile !== record);
        switch(record.boardLookupDTO?.boardGroupId){
            case BoardGroup.Hub:
                setHubCount(hubCount - 1)
                break;
            case BoardGroup.Fans:
                setFanCount(fanCount - 1)
                break;
            case BoardGroup.StackBoards:
                setStackCount(stackCount - 1)
                break;
            case BoardGroup.SensingBoards:
                setShbCount(shbCount - 1)
                break;
            case BoardGroup.RHTBoards:
                setRhtCount(rhtCount - 1)
                break;
            case BoardGroup.OPIBoards:
                setOpiCount(opiCount - 1)
                break;
        }
        // Update the state with the filtered array
        onUpdateDevices(updatedDeviceProfiles);
    }

    const onFinish = (values: any) =>{
        let lookup = pcbLookups.find(b=>b.name === values.boardLookup)!;
        let boardName = getBoardName(lookup);
        let boardId = getBoardId(lookup);

        const newDeviceProfile: DeviceProfileDTO = { name: boardName, canID:boardId, boardNumber:(boardId & 0x00F), canIDStr:`0x${boardId.toString(16)}`, sensors:[], actuators: [], boardLookupDTO: lookup }
        switch(values.boardType){
            case BoardGroup.Hub:
                setHubCount(1+hubCount);
                break;
            case BoardGroup.Fans:
                setFanCount(1+fanCount);
                break;
            case BoardGroup.StackBoards:
                setStackCount(1+stackCount);
                break;
            case BoardGroup.SensingBoards:
                setShbCount(1+shbCount);
                break;
            case BoardGroup.RHTBoards:
                setRhtCount(1+rhtCount);
                break;
            case BoardGroup.OPIBoards:
                setOpiCount(1+opiCount);
                break;
            default: 
                break;
        }
        // onUpdateDeviceProfiles([...deviceProfiles, newDeviceProfile], null);
        onUpdateDevices([...deviceProfiles, newDeviceProfile])
        form.resetFields(['boardType', 'boardLookup'])
        setAddEntryModalVisible(false)
    }


    return (
        <>
            <Table
            dataSource={sortedDevProfiles}
            columns={[
                
                { title: 'Name',   dataIndex: 'name',   key: 'name' },
                { title: 'CAN ID', dataIndex: 'canID',  key: 'canID', render: (text) => `0x${text.toString(16)}`},
                { title: 'Lookup', dataIndex: 'boardLookupDTO', key: 'boardLookupDTO', render:(text) => text.name },
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (record) => (
                        <Button onClick={() => handleDelete(record)}>Delete</Button>
                    ),
                },
            ]}
            >
            </Table>

            <Button onClick={() => setAddEntryModalVisible(true)}>Add New Entry</Button>

            <Modal
            title="Add New Entry"
            open={addEntryModalVisible}
            okButtonProps={{ style: { display: 'none' } }} // or { hidden: true }
            onCancel={() => setAddEntryModalVisible(false)}
            >

                <Form
                scrollToFirstError={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                >                
                    <Form.Item
                    label="Select Board Type"
                    name={"boardType"}
                    key={"boardType"}
                    initialValue={BoardGroup.Hub}
                    rules={[{ required: true }]}
                    style={{ marginBottom: "14px", marginTop: "8px" }}
                    >
                        <Select
                        title='Select board type'
                        style={{ width: '100%' }}
                        // onSelect={(value: BoardGroup) => { 
                        // onAddNewEntryFinish(value)}}
                        defaultValue={BoardGroup.Hub}
                        >
                            <Option value={BoardGroup.Hub}>Hub Board</Option>
                            <Option value={BoardGroup.Fans}>Fan Board</Option>
                            <Option value={BoardGroup.StackBoards}>Stack Board</Option>
                            <Option value={BoardGroup.SensingBoards}>Sensor Hub Board</Option>
                            <Option value={BoardGroup.RHTBoards}>RHT Board</Option>
                            <Option value={BoardGroup.OPIBoards}>OPI Board</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                    label="Select board lookup"
                    name={"boardLookup"}
                    key={"boardLookup"}
                    rules={[{ required: true }]}
                    style={{ marginBottom: "14px", marginTop: "8px" }}
                    >
                        <Select
                        title='Select board lookup'
                        style={{ width: '100%' }}
                        showSearch
                        options={
                            pcbLookups.filter(b=>b.boardGroupId === values).map( (pcb) =>({
                                label: pcb.name,
                                value: pcb.name
                            }))
                        }
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ float: "right" }}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    );
}