import { Tag, Typography } from "antd";
import React from "react";
import DeviceConnectionState from "src/consts/DeviceConnectionState";
import Role from "src/consts/Role";
import BinDTO from "src/models/BinDTO"
import CurrentUser from "src/utils/CurrentUser";
import RoleUtil from "src/utils/RoleUtil";

interface BinOfflineIndicatorProps {
    binDTO: BinDTO | null | undefined
}

export const ExtraOfflineInfoContext = React.createContext<{
    showExtraOfflineInfo: boolean,
    toggleOfflineInfo: () => void
}>({showExtraOfflineInfo: false, toggleOfflineInfo: () => {}});

export const useExtraOfflineInfoContext = () => {
    return React.useContext(ExtraOfflineInfoContext)
}

export const BinOfflineIndicator = (props: BinOfflineIndicatorProps) => {
    const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);

    const offlieInfoContext = useExtraOfflineInfoContext();

    if (props.binDTO == null) {
        return null;
    }
    if (props.binDTO.moduleConnectionState != DeviceConnectionState.Disconnected) {
        return null;
    }
    return (
        <Tag onClick={isAdmin ? offlieInfoContext.toggleOfflineInfo : undefined} color="red-inverse">
            <Typography.Text style={{color: 'inherit'}} strong>BIN OFFLINE</Typography.Text>
        </Tag>
    );
}